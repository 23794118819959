import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";

import { Router, Route, Switch } from "react-router-dom";
import { browserHistory } from "react-router";
import { createBrowserHistory } from "history";
// import { default as AuthenticatedRoutes } from 'routers/authenticated/Main';

import { PAGE_LOAD } from "patient_app/constants/actionTypes";
import { connect } from "react-redux";
import SessionStore from "patient_app/helpers/SessionStore";
import { retry } from "patient_app/helpers/supported";
import RudderstackTracker from "patient_app/helpers/RudderstackTracker";
//routes
//auth/reg
// import New from 'patient_app/views/users/New';
import Welcome from "patient_app/views/users/Welcome";

//activate
const ActivateUserId = React.lazy(() =>
  retry(() => import("patient_app/views/authentication/ActivateUserId"))
);
const ActivateCode = React.lazy(() =>
  retry(() => import("patient_app/views/authentication/ActivateCode"))
);
const SetPassword = React.lazy(() =>
  retry(() => import("patient_app/views/authentication/SetPassword"))
);
const Finish = React.lazy(() =>
  retry(() => import("patient_app/views/authentication/Finish"))
);

import FinishSigningUp from "patient_app/views/users/FinishSigningUp";
import { default as ResetPassword } from "patient_app/views/passwords/New";
import { default as EditPassword } from "patient_app/views/passwords/Edit";
import { default as NewSession } from "patient_app/views/sessions/New";
const TwoFactorAuth = React.lazy(() =>
  retry(() => import("patient_app/views/sessions/TwoFactorAuth"))
);
import Password from "patient_app/views/sessions/Password";
import DownloadApp from "patient_app/views/sessions/DownloadApp";

//onboarding
import Onboarding from "patient_app/routers/Onboarding";
import EnterpriseOnboarding from "patient_app/routers/EnterpriseOnboarding";
import Purchase from "patient_app/views/payments/Purchase";

//main application
import AccountRouter from "patient_app/routers/AccountRouter";
import DTORouter from "patient_app/routers/DTORouter";

import { default as Appointments } from "patient_app/views/appointments/Index";
import Checklist from "patient_app/views/users/Checklist";
import { default as Courses } from "patient_app/views/curriculums/Show";
import CourseSection from "patient_app/views/curriculums/CourseSection";
import { default as CommunityTab } from "patient_app/views/community/CommunityTab";
import { default as SchedulerTab } from "patient_app/views/sma/SchedulerTab";
import { default as ForumTab } from "patient_app/views/forum/ForumTab";
import { default as VideoRoom } from "patient_app/views/video_rooms/Room";
import ItemApproval from "patient_app/views/users/ItemApproval";
import ApproveTreatmentPlan from "patient_app/views/treatment_plans/ApproveTreatmentPlan";
import ZoomWebView from "patient_app/views/zoom_events/ZoomWebView";

//alcohol checks
const NewAlcoholCheck = React.lazy(() =>
  retry(() => import("patient_app/views/alcohol_checks/NewAlcoholCheck"))
);
const Clipboard = React.lazy(() =>
  retry(() => import("patient_app/views/gtc/Clipboard"))
);

import Receipt from "patient_app/views/payments/Receipt";
const PaymentHistoryReceipt = React.lazy(() =>
  retry(() => import("patient_app/views/payments/PaymentHistoryReceipt"))
);
const PendingItemUpload = React.lazy(() =>
  retry(() => import("patient_app/views/pending_items/PendingItemUpload"))
);

import NotFound from "components/utils/NotFound";
//end routes

import Errors from "patient_app/components/utils/Errors";
import { default as Loading } from "patient_app/components/utils/LoadingSpinner";
import OnboardingLoading from "patient_app/components/utils/OnboardingLoading";

const mapDispatchToProps = (dispatch) => {
  return {
    onPageLoad: () => {
      dispatch({ type: PAGE_LOAD });
    },
  };
};

const mapStateToProps = (state) => {
  return {
    videoIsPrev: state.video.videoIsPrev,
    errorsClass: state.common.errorsClass,
    errors: state.common.errors,
    formErrors: state.common.formErrors,
    errorsTimestamp: state.common.errorsTimestamp,
    user: state.common.user,
  };
};

class MasterRouter extends React.Component {
  history = createBrowserHistory();

  constructor(props) {
    super(props);
    this.state = {
      user: window.gon && window.gon.data ? window.gon.data.user : null,
    };
  }

  componentDidMount = () => {
    this.unlisten = this.history.listen((location, action) => {
      RudderstackTracker.pageView(this.props?.user?.id);
      this.props.onPageLoad();
      const el = document.getElementsByClassName("first_focus")[0];
      if (el) {
        el.focus();
      }
      window.scrollTo(0, 0);
    });

    SessionStore.setString("current_model", "user", { path: "/" });
    RudderstackTracker.pageView(this.props?.user?.id, this.props?.user?.email);
  };

  componentWillUnmount = () => {
    this.unlisten();
  };

  componentDidUpdate = (prevProps) => {
    // if (this.props.videoIsPrev && !location.pathname.includes('/video_room_queues')) {
    //   window.location.reload();
    //   //on page load for mobile, scroll to top
    //   window.scrollTo(0, 0);
    // }

    if (!prevProps.user && this.props.user) {
      RudderstackTracker.pageView(
        this.props?.user?.id,
        this.props?.user?.email
      );
    }

    if (
      this.props.errorsTimestamp &&
      this.props.errorsTimestamp != prevProps.errorsTimestamp
    ) {
      this.focusFirstError("focus-point-notification");
      this.focusFirstError("focus-point-notification-form-error");
    }
  };

  render() {
    return (
      <Router history={this.history}>
        <Errors />

        <React.Suspense fallback={this.determineLoadingComponent()}>
          <Switch location={location}>
            {/* unauthenticated pages */}
            <Route path="/activate" component={ActivateUserId} />
            <Route
              path="/authentication/activate_code"
              component={ActivateCode}
            />
            <Route
              path="/authentication/set_password"
              component={SetPassword}
            />
            <Route path="/authentication/finish" component={Finish} />
            <Route path="/download_app" component={DownloadApp} />
            <Route path="/finish_signing_up" component={FinishSigningUp} />
            <Route path="/signin" component={NewSession} />
            <Route path="/signup" component={Welcome} />
            <Route
              path="/two_factor_authentication"
              component={TwoFactorAuth}
            />
            <Route exact path="/password" component={Password} />
            <Route path="/password/new" component={ResetPassword} />
            <Route path="/password/edit" component={EditPassword} />

            {/* authenticated/onboarding pages */}
            <Route path="/alcohol_checks/new" component={NewAlcoholCheck} />
            <Route path="/appointments" component={Appointments} />
            <Route exact path="/community" component={CommunityTab} />
            <Route path="/schedule" component={SchedulerTab} />
            <Route path="/member_forum" component={ForumTab} />
            <Route exact path="/courses" component={Courses} />
            <Route exact path="/curriculums/:id" component={Courses} />
            <Route path="/curriculums/:id/section" component={CourseSection} />
            <Route path="/drug_test_orders/" component={DTORouter} />
            <Route
              path="/enterprise_onboarding"
              component={EnterpriseOnboarding}
            />
            <Route path="/onboarding/" component={Onboarding} />
            <Route
              path="/payments/payment_history"
              component={PaymentHistoryReceipt}
            />
            <Route path="/payments/purchase" component={Purchase} />
            <Route path="/payments/receipt" component={Receipt} />
            <Route
              path="/pending_items/:id/upload"
              component={PendingItemUpload}
            />
            <Route path="/profiles/:id/" component={AccountRouter} />
            <Route
              path="/treatment_plans/:id"
              component={ApproveTreatmentPlan}
            />
            <Route path="/users/checklist" component={Checklist} />
            <Route path="/users/:id/grab_the_clipboard" component={Clipboard} />
            <Route path="/users/:id/item_approval" component={ItemApproval} />
            <Route
              path="/video_room_queues/:id/waiting_room"
              component={VideoRoom}
            />
            <Route path="/zoom_web_view" component={ZoomWebView} />
            <Route exact path="/" component={NewSession} />
          </Switch>
        </React.Suspense>
      </Router>
    );
  }

  handleUpdateUser = (user) => {
    this.setState({ user: user });
  };

  focusFirstError = (className) => {
    const el = document.getElementsByClassName(className)[0];
    if (el) {
      el.focus();
    }
  };

  determineLoadingComponent = () => {
    let isOnboarding =
      (window.location.href.includes("onboarding") ||
        window.location.href.includes("signup") ||
        window.location.href.includes("demographics")) &&
      !window.location.href.includes("curriculum");
    if (isOnboarding) {
      return <OnboardingLoading />;
    } else {
      return <Loading className="lazy-load" />;
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MasterRouter);
