import PropTypes from "prop-types";
import React from "react";
import { DateTime } from "luxon";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchAccount,
  updateAccountProfile,
} from "patient_app/api/profileActions";
import {
  NEW_FIELD_SPECIFIC_ERROR,
  NEW_ERRORS,
  CLEAR_FIELD_SPECIFIC_ERRORS,
  PROFILE_UPDATE_STATE_LOCAL,
  OPEN_CHAT,
} from "patient_app/constants/actionTypes";

import ProfilePic from "patient_app/views/profiles/partials/account/ProfilePic";
import ProfileField from "patient_app/views/profiles/partials/ProfileField";
import TimeZoneField from "patient_app/components/input_fields/TimeZoneField";
import SubTabBack from "patient_app/views/profiles/partials/SubTabBack";
import LoadingSpinner from "patient_app/components/utils/LoadingSpinner";

import onboardingHelpers from "patient_app/helpers/onboardingHelpers";
import textHelpers from "patient_app/helpers/textHelpers";
import { mobileCheck } from "patient_app/helpers/supported";

import assets from "patient_app/assets";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAccount: (profileId, params) =>
      dispatch(fetchAccount(profileId, params)),
    updateAccountProfile: (user, profile) =>
      dispatch(updateAccountProfile(user, profile)),
    openChat: (data) => dispatch({ type: OPEN_CHAT, data: data }),
    newFieldSpecificError: (field, error) =>
      dispatch({ type: NEW_FIELD_SPECIFIC_ERROR, field: field, error: error }),
    newGeneralErrors: (errors) =>
      dispatch({ type: NEW_ERRORS, errors: errors }),
    clearFieldSpecificErrors: () =>
      dispatch({ type: CLEAR_FIELD_SPECIFIC_ERRORS }),
    setProfileState: (obj, value) =>
      dispatch({ type: PROFILE_UPDATE_STATE_LOCAL, obj: obj, value: value }),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.common.user,
    profile: state.common.profile,
    loading: state.profile.loading,
    success: state.profile.success,
  };
};

class AccountInfo extends React.Component {
  profileId = this.props.match.params.id;

  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      editUser: null,
      editProfile: null,
    };
  }

  componentDidMount = async () => {
    if (mobileCheck()) {
      this.props.fetchAccount(this.profileId, ["profile"]);
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.user && this.props.profile && !this.state.editUser) {
      this.resetState();
    }

    if (!prevProps.success && this.props.success) {
      this.resetState();
    }
  }

  render() {
    let { user, profile, loading } = this.props;

    let { editing, editUser, editProfile } = this.state;

    const genderIdentityOptions = onboardingHelpers.optionsForGenderIdentity();
    const sexOptions = onboardingHelpers.optionsForSexAtBirth();

    return (
      <div>
        <SubTabBack />

        <div className="profile-section">
          <div className="header">
            <h1>Account Info</h1>
            <button
              className="custom-button section-action"
              onClick={this.toggleEditProfile}
            >
              {editing ? "Cancel" : "Edit"}
            </button>
          </div>

          {!(editUser && editProfile) && <LoadingSpinner />}

          {editUser && editProfile && (
            <div>
              <ProfilePic />
              <ProfileField
                field="fir_name"
                fieldType={editing ? "text" : "plain-text"}
                onUpdateField={this.handleUpdateUser}
                title="First Name (Legal)"
                disabled={true}
                value={editUser.fir_name}
              />

              <ProfileField
                field="pref_name"
                fieldType={editing ? "text" : "plain-text"}
                onUpdateField={this.handleUpdateUser}
                title="First Name (Preferred)"
                value={editUser.pref_name}
              />

              <ProfileField
                field="las_name"
                fieldType={editing ? "text" : "plain-text"}
                onUpdateField={this.handleUpdateUser}
                title="Last Name (Legal)"
                value={editUser.las_name}
                disabled={true}
              />

              <ProfileField
                field="public_display_name"
                fieldType={editing ? "text" : "plain-text"}
                onUpdateField={this.handleUpdateUser}
                title="Public Display Name"
                value={editUser.public_display_name}
                disabled={false}
              />

              <ProfileField
                field="dob"
                fieldType={editing ? "date" : "plain-text"}
                onUpdateField={this.handleUpdateUser}
                title="Date of Birth"
                value={editUser.dob}
                disabled={true}
              />

              <ProfileField
                field="sex_at_birth"
                fieldType={editing ? "select" : "plain-text"}
                onUpdateField={this.handleUpdateProfile}
                options={sexOptions}
                showBlankOption={false}
                title="Sex Assigned At Birth"
                value={editProfile.sex_at_birth ? editProfile.sex_at_birth : ""}
              />

              <ProfileField
                field="gender_identity"
                fieldType={editing ? "select" : "plain-text"}
                onUpdateField={this.handleUpdateProfile}
                options={genderIdentityOptions}
                showBlankOption={true}
                title="Gender Identity"
                value={
                  editProfile.gender_identity ? editProfile.gender_identity : ""
                }
              />

              <ProfileField
                field="zip_code"
                fieldType={editing ? "text" : "plain-text"}
                onUpdateField={this.handleUpdateProfile}
                title="ZIP/Postal Code"
                value={editProfile.zip_code}
              />

              {editing ? (
                <TimeZoneField
                  title="Time Zone"
                  timeZone={editProfile.time_zone}
                  onSelect={(value) =>
                    this.handleUpdateProfile(value, "time_zone")
                  }
                />
              ) : (
                <ProfileField
                  field="time_zone"
                  fieldType="plain-text"
                  title="Time Zone"
                  value={editProfile.time_zone}
                />
              )}

              <ProfileField
                field="email"
                fieldType="plain-text"
                title="Email"
                value={user.email}
              />

              <ProfileField
                className="no-margin"
                field="phone"
                fieldType="plain-text"
                title="Phone"
                value={user.phone ? textHelpers.formatPhone(user.phone) : "N/A"}
              />

              {editing && (
                <p style={{ margin: "1rem 0 2rem" }}>
                  Please{" "}
                  <a className="link" href="#" onClick={this.handleChat}>
                    message your care team
                  </a>{" "}
                  to update your legal name, date of birth, phone number or
                  email address.
                </p>
              )}

              {editing && (
                <button
                  className={`custom-button submit ${loading ? "loading" : ""}`}
                  onClick={this.handleSubmit}
                >
                  Save Changes
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  toggleEditProfile = () => {
    if (this.state.editing) {
      this.resetState();
      this.props.clearFieldSpecificErrors();
      this.props.newGeneralErrors([]);
    }

    this.setState({ editing: !this.state.editing });
  };

  resetState = () => {
    const { user, profile } = this.props;
    this.setState({
      editing: false,
      editUser: {
        fir_name: user.fir_name,
        las_name: user.las_name,
        pref_name: user.pref_name,
        public_display_name: user.public_display_name,
        dob: this.formatDOB(),
      },
      editProfile: {
        sex_at_birth: profile.sex_at_birth || profile.gender,
        gender_identity: profile.gender_identity || "",
        gender: profile?.gender || "",
        time_zone: profile?.time_zone || "",
        zip_code: profile?.zip_code || "",
      },
    });
  };

  formatDOB() {
    let { user } = this.props;
    if (!user) {
      return;
    }

    let fromDateTime = user.dob
      ? DateTime.fromFormat(user.dob, "yyyy-MM-dd")
      : "";
    if (fromDateTime?.isValid) {
      return fromDateTime.toFormat("MM/dd/yyyy");
    }

    return user.dob;
  }

  handleUpdateUser = (value, field) => {
    let user = this.state.editUser;
    user[field] = value;
    this.setState({ editUser: user });
  };

  handleUpdateProfile = (value, field) => {
    let profile = this.state.editProfile;
    profile[field] = value;
    this.setState({ editProfile: profile });
  };

  handleSubmit = () => {
    const user = this.state.editUser;
    const profile = this.state.editProfile;

    this.props.clearFieldSpecificErrors();
    this.props.newGeneralErrors([]);
    let errors = this.validate();
    if (errors.length > 0) {
      for (const [key, error] of Object.entries(errors)) {
        this.props.newFieldSpecificError(error.field, error.text);
      }
      window.scroll(0, 0);
      this.props.newGeneralErrors([
        { text: "Please correct the issues listed below." },
      ]);
      return;
    }

    profile.id = this.props.profile.id;
    this.props.updateAccountProfile(user, profile);
  };

  validate = () => {
    const user = this.state.editUser;
    const profile = this.state.editProfile;
    let errors = [];

    if (!user.fir_name || user.fir_name.length < 2) {
      errors.push({
        field: "fir_name",
        text: "First name must be two or more characters.",
      });
    }

    if (!user.las_name || user.las_name.length < 2) {
      errors.push({
        field: "las_name",
        text: "Last name must be two or more characters.",
      });
    }

    if (!user.dob || user.dob.length < 10) {
      errors.push({
        field: "dob",
        text: "Please enter a valid date of birth.",
      });
    }

    if (!profile.gender) {
      errors.push({ field: "gender", text: "Please select a gender" });
    }

    if (!profile.zip_code || profile.zip_code.length < 5) {
      errors.push({
        field: "zip_code",
        text: "Please enter a valid zip code.",
      });
    }

    return errors;
  };

  handleChat = (e) => {
    e.preventDefault();
    const message = "Hi, I would like to edit my account details.";
    this.props.openChat({
      view: "index",
      prepopulatedMessage: message,
      topic: "ADMIN",
    });
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccountInfo)
);
